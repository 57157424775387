import React, {useState} from 'react';
import {Link} from 'react-scroll';
import {Link as GatsbyLink} from 'gatsby';

import Hamburger from '../components/Hamburger';
import Menu from '../components/Menu';
import Button from '../components/Button'

export default function Navbar(props) {
    const [isMenuOpen, setMenu] = useState(false);

    return (
        <div className="navbar-container">
            <nav>
                <GatsbyLink aria-label="home page link" className="logo-link" to="/">
                    <div className="logo-container"/>
                </GatsbyLink>
                <div className="links-container">
                    {(props.pageName != '/') ? 

                        <ul>
                            <li> <GatsbyLink className="nav-link" activeClass="active-link" to="/" >HOME</GatsbyLink> </li>
                            <li> <GatsbyLink className="nav-link" activeClass="active-link" to="/#AboutUs" smooth={true} duration={500}>ABOUT US</GatsbyLink> </li>
                            <li> <GatsbyLink className="nav-link" activeClass="active-link" to="/#Services" smooth={true} >SERVICES</GatsbyLink> </li>
                            <li> <GatsbyLink className="nav-link" activeClass="active-link" to="/gallery" > PROJECT GALLERY</GatsbyLink> </li>
                            <li><Button otherClassName="navbar-button" buttonType="1" linkType="gatsby" to="/contact" text="CONTACT US &thinsp;&rarr;"/></li>
                        </ul>
                    
                    : 
                        <ul>
                            <li> <GatsbyLink className="nav-link" activeClass="active-link" to="/" spy={true} >HOME</GatsbyLink> </li>
                            <li> <Link className="nav-link" activeClass="active-link" to="AboutUs" smooth={true} duration={500}>ABOUT US</Link> </li>
                            <li> <Link className="nav-link" activeClass="active-link" to="Services" smooth={true} >SERVICES</Link> </li>
                            <li> <GatsbyLink className="nav-link" activeClass="active-link" to="/gallery" > PROJECT GALLERY</GatsbyLink> </li>
                            <li><Button otherClassName="navbar-button" buttonType="1" linkType="gatsby" to="/contact" text="CONTACT US &thinsp;&rarr;"/></li>
                        </ul>
                    }
                </div>
                <div className="ham-menu-container">
                    <Hamburger isMenuOpen={isMenuOpen} setMenu={setMenu}/>
                </div>
            </nav>
            <Menu 
                isMenuOpen={isMenuOpen} 
                setMenu={setMenu}
                pageName={props.pageName}
            />
        </div>
    )
}
