import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle, faChevronCircleLeft, faChevronCircleRight} from '@fortawesome/free-solid-svg-icons';

export default function Lightbox(props) {
    const data = useStaticQuery(graphql`
        query lightbox{
            allFile(
                filter: {relativeDirectory: {eq: "galleryImages"}}
                sort: {fields: name, order: ASC}
              ) {
                edges {
                  node {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
                      fields {
                        exif {
                          raw {
                            image {
                              ImageDescription
                            }
                          }
                        }
                      }
                    }
                    name
                  }
                }
              }
        }
    `);

    function handleExit(){
        props.setLightBox(false);
        if(typeof document !== 'undefined'){
            document.body.classList.remove("no-scroll");
        }
    }

    function handleNext(){
        if(typeof document !== 'undefined'){
            let currActive = document.querySelector(`.img${props.index}`);
            currActive.classList.remove("active");
            currActive.classList.add("hide");
            currActive = document.querySelector(`.img${(props.index + 1) % 7}`)
            currActive.classList.remove("hide");
            currActive.classList.add("active");
            props.setIndex((props.index +1) % 7);
        }
    }
    function handlePrev(){
        if(typeof document !== 'undefined'){
            let currActive = document.querySelector(`.img${props.index}`);
            let newIndex = ((props.index - 1) + 7) % 7;
            currActive.classList.remove("active");
            currActive.classList.add("hide");
            currActive = document.querySelector(`.img${newIndex}`)
            currActive.classList.remove("hide");
            currActive.classList.add("active");
            props.setIndex(newIndex);
        }
    }

    return (
        <div className={`lightbox-container ${(props.lightboxOn) ? "active" : ""}`}>
            <div className="lightbox-x"onClick={handleExit}>
                <FontAwesomeIcon icon={faTimesCircle}/>
            </div>
            <div className="next-icon-container" onClick={handleNext}>
                <FontAwesomeIcon icon={faChevronCircleRight}/>
            </div>
            <div className="prev-icon-container" onClick={handlePrev}>
                <FontAwesomeIcon icon={faChevronCircleLeft}/>
            </div>
            {data.allFile.edges.map((edge, index) => {
                return(
                    <GatsbyImage
                        alt={edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}
                        image={getImage(edge.node)}
                        className={`lightbox-image-container ${props.index == index ? "active" : "hide"} img${index}`}
                        key={`lb${index}`}
                        objectPosition={"50% 50%"}
                        objectFit="contain"
                    />
                )
            })}
        </div>
    )
}
