import React from 'react';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';

export default function SEO(props) {

    const data = useStaticQuery(graphql`
        query seo{
            file(relativePath: {eq: "landingHouse_960x640.jpg"}) {
                publicURL
              }
        }
    `);
    return (
        <div>
            <Helmet htmlAttributes={{lang: 'en'}}>
                <title>NorthStar Home Solutions</title>
                <meta name="description" content="Your One Stop Shop For Home Remodeling | Located in Fairfax Va | (571) 354-6067"/>
                <meta 
                    name="keywords" 
                    content="
                        home, construction, improvement, home improvement, handyman, renovation, contractor, bathroom remodel, 
                        remodeling, bathroom renovations, kitchen remodel, general contractor, handyman services, kitchen renovation,
                        home renovation, home remodeling, home repair, bathroom remodel cost"
                />
                
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@northstarhsllc" />
                <meta name="twitter:title" content="NorthStar Home Solutions" />
                <meta name="twitter:description" content="Your One Stop Shop For Home Remodeling | Fairfax VA | (571) 354-6067" />
                <meta name="twitter:image" content={data.file.publicURL} />
                
                <meta property="og:url"                content="https://www.northstarhsllc.com" />
                <meta property="og:type"               content="website" />
                <meta property="og:title"              content="NorthStar Home Solutions" />
                <meta property="og:description"        content="Your One Stop Shop For Home Remodeling | Located in Fairfax VA | (571) 354-6067" />
                <meta property="og:image"              content={data.file.publicURL} />

                <link rel="canonical" href={`https://www.NorthStarhsllc.com${props.pageName}`}/>
            </Helmet>
        </div>
    )
}
