import React from 'react';
import {Link} from 'react-scroll';
import Button from '../components/Button'
import {Link as GatsbyLink} from 'gatsby';

export default function Menu(props) {

    function handleClick(){
        props.setMenu(!props.isMenuOpen);
    }

    return (
        <div className={`drop-down-menu ${props.isMenuOpen ? "open" : "closed"}`}>
            <nav>
                {(props.pageName != "/") ? 

                    <ul>
                        <li> <GatsbyLink onClick={handleClick} className="nav-link" activeClass="active-link" to="/" >HOME</GatsbyLink> </li>
                        <li> <GatsbyLink onClick={handleClick} className="nav-link" activeClass="active-link" to="/#AboutUs" smooth={true} spy={true} duration={500}>ABOUT US</GatsbyLink> </li>
                        <li> <GatsbyLink onClick={handleClick} className="nav-link" activeClass="active-link" to="/#Services" smooth={true} spy={true} >SERVICES</GatsbyLink> </li>
                        <li> <GatsbyLink onClick={handleClick} className="nav-link" activeClass="active-link" to="/gallery" spy={true} > PROJECT GALLERY</GatsbyLink> </li>
                        <li><Button buttonType="3" linkType="gatsby" to="/contact" text="CONTACT US &thinsp;&rarr;"/></li>
                    </ul> 
                : 
                    <ul>
                        <li> <GatsbyLink onClick={handleClick} className="nav-link" activeClass="active-link" to="/" >HOME</GatsbyLink> </li>
                        <li> <Link onClick={handleClick} className="nav-link" activeClass="active-link" to="AboutUs" smooth={true} spy={true} duration={500}>ABOUT US</Link> </li>
                        <li> <Link onClick={handleClick} className="nav-link" activeClass="active-link" to="Services" smooth={true} spy={true} >SERVICES</Link> </li>
                        <li> <GatsbyLink onClick={handleClick} className="nav-link" activeClass="active-link" to="/gallery" spy={true} > PROJECT GALLERY</GatsbyLink> </li>
                        <li><Button buttonType="3" linkType="gatsby" to="/contact" text="CONTACT US &thinsp;&rarr;"/></li>
                    </ul>
                }
            </nav>
        </div>
    )
}