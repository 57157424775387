import React from 'react';
import {Link} from 'react-scroll';
import {Link as GatsbyLink} from 'gatsby';

export default function Button(props) {
    return (
        <div className="button-container">
            {
                (props.linkType == 'gatsby') ? 
                <GatsbyLink to={props.to} className={`button-link ${`button${props.buttonType}`} ${props.otherClassName}`} activeClassName="landing-button-link" > {props.text} </GatsbyLink> :
                (props.linkType == 'anchor') ? <a href={props.to} className={`button-link ${`button${props.buttonType}`} ${props.otherClassName}`}> {props.text} </a> : <Link className={`button-link ${props.otherClassName}`} activeClass="landing-button-link" to={props.to} smooth={true}>{props.text} </Link>
            } 
        </div>
    )
}
