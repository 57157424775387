import React from 'react';

export default function Hamburger(props) 
{   
    const handleHamClick = () =>{
        props.setMenu(!props.isMenuOpen);
    }

    const handleKeyPress = (e) =>{
        if(e.key === 'Enter'){
            handleHamClick();
        }
    }

    return (
        <div aria-label="hamburger menu" role="button" tabindex="0" onKeyPress={handleKeyPress} className={`hamburger-btn ${props.isMenuOpen ? "open" : "closed"}`} onClick={handleHamClick}>
            <div className={`hamburger ${props.isMenuOpen ? "open" : "closed"}`}/>
        </div>
    )
}